<template>
  <div class="row">
      <div class="col-md-12 mt-5">
            <el-table
                :data="tableData"
                :header-cell-style="{ background:'rgba(212, 216, 226, 0.2)'}"
                style="width: 100%"
                :empty-text="'No Data Available'"
                height="40vh"
                >
                <el-table-column
                    label="Transactions"
                    prop="Transactions"
                    >
                </el-table-column>
                <el-table-column
                    label="Amount"
                    prop="Amount"
                    >
                </el-table-column>
                <el-table-column
                    label="Head About Us"
                    prop="heard_about_us"
                    >
                </el-table-column>
            </el-table>
        </div>
  </div>
</template>

<script>
export default {
    props: {
        tableData: {
            required: true,
            type:Array
        },
    }

}
</script>

<style>

</style>