<template>
  <div class="row ">
    <div class="scroll is-full-container">
      <el-form :model="formData" :rules="rules" ref="ruleForm" @submit.prevent.native="checkEnter">
        <div class="row justify-content-center">
          <div class="form_input_container">
            <el-form-item label="From" style="width: 100%" prop="date_from">
              <el-date-picker v-model="formData.date_from" type="date" style="width: 100%; height: 50px !important"
                placeholder="Select date" value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="col-md-3 form_input_container">
            <el-form-item label="To" prop="date_to">
              <el-date-picker v-model="formData.date_to" type="date" style="width: 100%; height: 50px !important"
                placeholder="Select date" value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="">
            <div class="form_input_container">
              <el-form-item label="Criteria" prop="criteria">
                <el-select style="width: 100%; height: 48px" v-model="formData.criteria" filterable
                  placeholder="Select criteria">
                  <el-option v-for="(criteria, key) in criterias" :key="key" :label="criteria.name"
                    :value="criteria.value" class="criteria-select">
                    <span>{{ criteria.name }}</span>
                    <span>{{ criteria.showTable == true ? "Table" : "Count" }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="col-md-2 d-flex align-items-center">
            <div class="button_wrapper">
              <el-button :loading="tableLoading" @click.prevent="view('ruleForm')">
                View
              </el-button>
            </div>
          </div>
        </div>
      </el-form>
      <div class="is-full-table" v-if="checkIfShowTable">
        <span>Count:{{ displayedData.length }}</span>
        <el-table :header-cell-style="{ background: 'rgba(212, 216, 226, 0.2)' }" style="width: 100%"
          :data="displayedData" :empty-text="'No Data Available'" height="46vh"
          v-if="$route.path.includes('admin/reporting/business_chasis')" v-loading="tableLoading">
          <el-table-column label="Name" prop="name">
            <template slot-scope="scope">
              <p>
                {{ scope.row.name ? scope.row.name : scope.row.user.name }}
              </p>

            </template>
          </el-table-column>
          <el-table-column label="Phone Number" prop="number">
            <template slot-scope="scope">
              <p>
                {{ scope.row.phone ? scope.row.phone : scope.row.user.phone }}
              </p>

            </template>
          </el-table-column>
        </el-table>
        <div class="col-md-12" style="text-align: center; margin-top:2em;">
          <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange" :page-size="pageSize"
            :total="total">
          </el-pagination>
        </div>
      </div>
      <div class="will-show-count" v-else>
        <span>Count:{{ displayCount }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicTable from "./dynamicTable";
import BusinessChasis from "./BusinessChasis";
import store from "@/state/store.js";
export default {
  props: {
    criterias: {
      type: Array,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    viewType: {
      required: true,
      type: String,
    },
  },
  components: {
    DynamicTable,
    BusinessChasis,
  },
  data() {
    return {
      tableLoading: false,
      total: 0,
      pageSize: 1,
      loading: false,
      tableData: [],
      dataCount: 0,
      formData: {
        criteria: "",
        date_from: "",
        date_to: "",
      },
      rules: {
        criteria: [
          {
            required: true,
            message: "Criteria is required",
            trigger: ["change", "blur"],
          },
        ],
        date_from: [
          {
            required: true,
            message: "Date from is required",
            trigger: ["change", "blur"],
          },
        ],
        date_to: [
          {
            required: true,
            message: "Date to is required",
            trigger: ["change", "blur"],
          },
        ],
      },
      displayData: '',
      businessChasis: []


    };
  },
  methods: {
    view(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.tableLoading = true;
          store
            .dispatch("admin/reporting/" + this.action, this.formData)
            .then((response) => {
              if (this.viewType === "business_chasis") {
                // Find criteria from criterias array (prop)
                const criteria = this.criterias.find(
                  (criteria) => criteria.value === this.formData.criteria
                );
                // If criteria needs to show table put in 'response.data' and have an early return else put in only the count
                if (criteria.showTable == false) {
                  this.dataCount = response
                  return
                }
                this.tableData = response?.data || [];
                this.total = response.total
                this.pageSize = response.per_page

              } else {
                this.tableData = response;
              }
            })
            .catch((err) => {
              if (err?.response?.data == undefined) {
                this.errors = "Network error try again";
              }
            })
            .finally(() => (this.tableLoading = false));
        } else {
          return false;
        }
      });
    },
    handleCurrentChange(val) {
      this.tableLoading = true;
      store
        .dispatch("admin/reporting/" + this.action, {
          ...this.formData,
          page: val,
        })
        .then((response) => {
          this.tableData = response.data || [];
          this.total = response.total;
          this.pageSize = response.per_page
        })
        .catch((err) => {
          if (err?.response?.data == undefined) {
            this.errors = "Network error try again";
          }
        })
        .finally(() => (this.tableLoading = false));
    },
  },
  computed: {
    displayedData() {
      return this.tableData || []
    },
    checkIfShowTable() {
      const criteria = this.criterias.find(
        (criteria) => criteria.value === this.formData.criteria
      );
      if (criteria) {
        return criteria.showTable;
      }
    },
    displayCount() {
      return this.dataCount || 0
    }
  },
};
</script>

<style scoped>
button {
  outline: none;
  border: none;
  background-color: #f7921c;
  margin-top: 2em;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 159.3%;
  text-align: center;
  color: #fff;
  height: 48px;
  width: 100px;
  border-radius: 5px;
}

button:hover {
  background-color: #f7921c;
  color: #fff;
}

.scroll {
  height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.display {
  align-content: center;
  width: min(90%, 1200px);
  height: 130px;
  margin: auto;
  border-radius: 20px;
  margin-block: 1rem;
}

.count {
  padding: 1px;
  color: black;
}

.is-full-container {
  width: 100% !important;
  margin: 0 auto;
  height: 100%;
}

.is-full-table {
  width: 90%;
  margin: 0 auto;
}

.criteria-select span:nth-child(1) {
  float: left;
  justify-content: space-between;
  margin-right: 1rem;
}

.criteria-select span:nth-last-child(1) {
  float: right;
  justify-content: space-between;
  font-style: italic;
}

.will-show-count {
  display: flex;
  justify-content: center;
}

.will-show-count span {
  font-size: 1.5em;
  font-weight: 600;
  margin: auto 0;
  color: #655858;
  border: 1px solid #f7921c;
  padding: 3em 5em;
}
.is-full-table span {
  font-size: 0.8em;
  font-weight: 20;
  margin: auto 0;
  color: #655858;
  border: 0.5px solid #f7921c;
  padding: 0.3em 1em;
}
</style>
