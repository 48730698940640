<template>
  <div class="row justify-content-center">
    <div class="col-sm-11 col-md-4 col-lg-5 mt-5">
      <div class="card px-3 py-5">
        <h2 class="text-center">Number of Customers ({{ getCriteria }})</h2>
        <p class="text-center">{{ responseData.value }}</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    responseData: {
      required: true,
      type: Object,
    },
  },
  computed: {
    getCriteria() {
      switch (this.responseData.criteria) {
        case "leads_monthly":
          return "Leads Monthly";
        case "top_ups_sms":
          return "SMS Topups";
        case "top_ups_voice":
          return "Voice Topups";
        case "sum_top_ups_sms":
          return "Sum SMS Topups";
        case "sum_top_ups_voice":
          return "Sum Voice Topups";
        case "revenue":
          return "Top Up Voice";
        case "dormant_accounts":
          return "Dormant Accounts ";
        case "revenue_generated":
          return "Monthly New Users ";
        case "new_users_top_ups":
          return "Monthly New TopUps";
        case "deactivated_accounts":
          return "Deactivated Accounts";
      }
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 18px;
  font-weight: normal;
}
p {
  font-weight: bolder;
  font-size: 34px;
}
</style>
