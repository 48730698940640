<template>
  <div class="">
      <SalesAndBusinessBI :criterias="criterias" :action="action" :viewType="viewType" />
  </div>
</template>

<script>
import SalesAndBusinessBI from '../global/salesAndBusinessBI.vue'
import appConfig from '@/app.config'

export default {
    page: {
		title: 'Sales and Market BI reports',
		meta: [{ name: 'description', content: appConfig.description }],
	},
    components: {
        SalesAndBusinessBI
    },
    data() {
        return {
            action:'salesMarket',
            viewType: 'sales',
            criterias:[
                    {
                        name:'Individual Heard About Us Bundle',
                        value:'heard_abt_us_bundle_individual'
                    },
                    {
                        name:'Corporate Heard About Us Bundle',
                        value:'heard_abt_us_bundle_coporate'
                    },
                    {
                        name:'Register Individual Heard About Us',
                        value:'heard_abt_us_register_individual'
                    },
                    {
                        name:'Register Corporate Heard About Us',
                        value:'heard_abt_us_register_coporate'
                    },
                    {
                        name:'Topup In Two Weeks',
                        value:'top_up_in_2_wks'
                    },
                    {
                        name:'Total Sales',
                        value:'total_sales'
                    },
                ]
        }
    }
}
</script>
<style>
</style>
